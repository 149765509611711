/* ------------------------------------------------------------------------------
	Template Name: Minifolio
	Author: Designstub
	Author URI: http://www.designstub.com
	License: GNU General Public License version 3.0
	License URI: http://www.gnu.org/licenses/gpl-3.0.html
	Version: 1.0

/* ------------------------------------------------------------------------------
	Typography
-------------------------------------------------------------------------------*/

@import url(http://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700);
@import url(http://fonts.googleapis.com/css?family=Roboto%20Slab:300,400,500,600,700);

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

p {
  font-size: 14px;
  line-height: 22.4px;
  color: #6c7279;
}
h1 {
  font-size: 65px;
  color: #2d3033;
}
h2 {
  font-size: 40px;
  color: #2d3033;
}
h3 {
  font-size: 28px;
  color: #2d3033;
  font-weight: 300;
}
h4 {
  font-size: 22px;
  color: #2d3033;
  font-weight: 400;
}
h5 {
  font-size: 14px;
  color: #2d3033;
  text-transform: uppercase;
  font-weight: 700;
}
.btn {
  background-color: #3bc492;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 5px;
  border: 0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  text-transform: uppercase;
}
.btn:hover,
.btn:focus {
  background-color: #3d3d3d;
  color: #fff;
}
.btn-large {
  padding: 15px 40px;
}
/* ------------------------------------------------------------------------------
	Global Styles
-------------------------------------------------------------------------------*/
a {
  color: #e84545;
}
a:hover,
a:focus {
  text-decoration: none;
  -moz-transition: background-color, color, 0.3s;
  -o-transition: background-color, color, 0.3s;
  -webkit-transition: background-color, color, 0.3s;
  transition: background-color, color, 0.3s;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6c7279;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
.section {
  padding: 100px 0;
}
.no-padding {
  padding: 0;
}
.no-gutter [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.space {
  margin-top: 60px;
}
/* ------------------------------------------------------------------------------
	Header
-------------------------------------------------------------------------------*/
#header {
  width: 100%;
  z-index: 999;
}
#header .header-content {
  margin: 0 auto;
  max-width: 1170px;
  padding: 60px 0 0 0;
  width: 100%;
  transition: padding 0.3s;
}
#header .logo {
  float: left;
}
#header.fixed {
  background-color: rgba(0, 0, 0, 0.25);
}
#header.fixed .header-content {
  border-bottom: 0;
  padding: 25px 0;
}
#header.fixed .nav-toggle {
  top: 18px;
}
.navigation.open {
  opacity: 0.9;
  visibility: visible;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.navigation {
  float: right;
  margin-right: 36px;
}
.navigation li {
  display: inline-block;
}
.navigation a {
  color: rgb(202 202 202);
  font-size: 15px;
  font-weight: 400;
  margin-left: 40px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
}
.navigation a:hover,
.navigation a.active {
  color: #fff;
}
.nav-toggle {
  display: none;
  height: 44px;
  overflow: hidden;
  position: fixed;
  right: 5%;
  text-indent: 100%;
  top: 32px;
  white-space: nowrap;
  width: 44px;
  z-index: 99999;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.nav-toggle:before,
.nav-toggle:after {
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
}
.nav-toggle:before {
  background-color: #4b1685;
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.nav-toggle:after {
  background-color: #4b1685;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  -moz-transition-duration: 0s;
  -o-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.nav-toggle span {
  background-color: #fff;
  bottom: auto;
  display: inline-block;
  height: 3px;
  left: 50%;
  position: absolute;
  right: auto;
  top: 50%;
  width: 18px;
  z-index: 10;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.nav-toggle span:before,
.nav-toggle span:after {
  background-color: #fff;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transition: -moz-transform 0.3s;
  -o-transition: -o-transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.nav-toggle span:before {
  -moz-transform: translateY(-6px) rotate(0deg);
  -ms-transform: translateY(-6px) rotate(0deg);
  -webkit-transform: translateY(-6px) rotate(0deg);
  transform: translateY(-6px) rotate(0deg);
}
.nav-toggle span:after {
  -moz-transform: translateY(6px) rotate(0deg);
  -ms-transform: translateY(6px) rotate(0deg);
  -webkit-transform: translateY(6px) rotate(0deg);
  transform: translateY(6px) rotate(0deg);
}
.nav-toggle.close-nav:before {
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.nav-toggle.close-nav:after {
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.nav-toggle.close-nav span {
  background-color: rgba(255, 255, 255, 0);
}
.nav-toggle.close-nav span:before,
.nav-toggle.close-nav span:after {
  background-color: #fff;
}
.nav-toggle.close-nav span:before {
  -moz-transform: translateY(0) rotate(45deg);
  -ms-transform: translateY(0) rotate(45deg);
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}
.nav-toggle.close-nav span:after {
  -moz-transform: translateY(0) rotate(-45deg);
  -ms-transform: translateY(0) rotate(-45deg);
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}
/* ------------------------------------------------------------------------------
	Banner
-------------------------------------------------------------------------------*/

.banner {
  background-color: black;
  /* min-height: 750px; */
  height: 100vh;
  width: 100vw;
}
.banner-text {
  padding-top: 28%;
}
.banner-text h1 {
  color: #fff;
  font-family: "Roboto Slab", sans-serif;
  font-size: 80px;
  font-weight: 700;
}
.banner-text p {
  color: #fff;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 80px;
}
/* ------------------------------------------------------------------------------
	Description Text
-------------------------------------------------------------------------------*/

.descripton p {
  color: #3d3d3d;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 30px;
}
/* ------------------------------------------------------------------------------
	Works
-------------------------------------------------------------------------------*/
.work {
  -moz-box-shadow: 0 0 0 1px #fff;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;

  overflow: hidden;
  position: relative;
  visibility: hidden;
}
.work img {
  width: 100%;
  height: 100%;
}
.work .overlay {
  background: rgba(64, 35, 97, 0.9);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -moz-transition: opacity, 0.3s;
  -o-transition: opacity, 0.3s;
  -webkit-transition: opacity, 0.3s;
  transition: opacity, 0.3s;
}
.work .overlay-caption {
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.work h5,
.work p,
.work img {
  -moz-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  -webkit-transition: all, 0.5s;
  transition: all, 0.5s;
}
.work h5,
.work p {
  color: #fff;
  margin: 0;
  opacity: 0;
}
.work h5 {
  margin-bottom: 5px;
  -moz-transform: translate3d(0, -200%, 0);
  -ms-transform: translate3d(0, -200%, 0);
  -webkit-transform: translate3d(0, -200%, 0);
  transform: translate3d(0, -200%, 0);
}
.work p {
  -moz-transform: translate3d(0, 200%, 0);
  -ms-transform: translate3d(0, 200%, 0);
  -webkit-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0);
}

.work-box:hover img {
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.work-box:hover .overlay {
  opacity: 1;
}
.work-box:hover .overlay h5,
.work-box:hover .overlay p {
  opacity: 1;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* ------------------------------------------------------------------------------
	Work animation
-------------------------------------------------------------------------------*/
.work:nth-child(1) {
  -moz-animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.work:nth-child(2) {
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.work:nth-child(3) {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.work:nth-child(4) {
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.work:nth-child(5) {
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.work:nth-child(6) {
  -moz-animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.work:nth-child(7) {
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.work:nth-child(8) {
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animated {
  visibility: visible;
}

/* ------------------------------------------------------------------------------
	Hire me
-------------------------------------------------------------------------------*/
.hireme {
  /*background-color: black;*/
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.hireme h3 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin-top: 0;
}
.hireme p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.hireme .btn {
  width: 300px;
  background-color: black;
  color: white;
  font-weight: 900;
  margin-top: 30px;
  font-size: 25px;
}
/* ------------------------------------------------------------------------------
	Footer
-------------------------------------------------------------------------------*/

.footer p {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
}
.footer a {
  color: #7f7f7f;
}
.footer a:hover {
  color: #e84545;
}
.footer .footer-share {
  margin-top: 0;
}
.footer .footer-share li {
  background: #3d3d3d none repeat scroll 0 0;
  border-radius: 50%;
  color: #ffffff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 4px 0;
  margin: 0 5px;
  display: inline-block;
  float: none;
}
.footer .footer-share a {
  color: #fff;
}
.footer .fa-heart {
  color: #e84545;
  font-size: 11px;
  margin: 0 2px;
}

/* ------------------------------------------------------------------------------
	Inner Page Banner
-------------------------------------------------------------------------------*/

.innerbanner {
  background-color: black;
  min-height: 100%;
}
.innerbanner-text {
  padding-top: 20%;
  padding-bottom: 5%;
}

.innerbanner-text p {
  color: #fff;
  font-family: "Roboto Slab", sans-serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 80px;
}
/* ------------------------------------------------------------------------------
	Inner description
-------------------------------------------------------------------------------*/
.inner-description h1 {
  color: #3d3d3d;
  font-size: 23px;
  font-weight: 700;
  margin-top: 55px;
}
.inner-description p {
  color: #3d3d3d;
  font-size: 18px;
  line-height: 33px;
  font-weight: 400;
}
.inner-description img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

/* ------------------------------------------------------------------------------
	Contact form
-------------------------------------------------------------------------------*/
.conForm {
  margin-top: 50px;
}
.conForm input {
  background: #fafafa;
  color: #797979;
  padding: 15px 30px;
  border: none;
  margin-right: 3%;
  margin-bottom: 30px;
  outline: none;
  font-style: normal;
  border: #f4f3f3 1px solid;
  font-size: 15px;
}
.conForm input.noMarr {
  margin-right: 0px;
}
.conForm textarea {
  background: #fafafa;
  color: #797979;
  padding: 15px 30px;
  margin-bottom: 18px;
  outline: none;
  height: 300px;
  font-style: normal;
  resize: none;
  font-size: 15px;
  border: #f4f3f3 1px solid;
}

.conForm .submitBnt {
  background: #3bc492;
  color: #fff;
  padding: 15px 40px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 5px;
  border: 0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  text-transform: uppercase;
}
.conForm .submitBnt:hover {
  background: #3d3d3d;
  color: #fff;
}
.error_message {
  color: #ff675f;
  font-weight: 500;
  padding-bottom: 15px;
}
#success_page h3 {
  color: #5ed07b;
  font-size: 17px;
  font-weight: 700;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #cacaca;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.works {
  text-align: left;
  padding: 36px;
  text-align: left;
  padding: 36px;
  background-color: black;
  color: white;
}

footer {
  padding: 20px !important;
}

.grid {
  display: flex;
  overflow-x: scroll;
}

.instagram {
  background-color: black;
  overflow: hidden;
}

.wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: black;
  overflow-x: scroll;
  display: flex;
}

.instagram h1 {
  color: white;
}

.rsme-embed {
  margin-right: 20px;
}

.grid {
  display: flex;
}

html {
  overflow: hidden;
  overflow-y: scroll;
}

.footer {
  text-align: center;
  text-align: center;
  background-color: black;
  color: white;
}

.h1,
h1 {
  font-size: 36px;
  text-align: center;
}

.facebook .wrapper div {
  margin-right: 20px;
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 0px;
}

.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1);
}

.works .h1,
.works h1 {
  font-size: 36px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
}

.works .h2,
.works h2 {
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: bold;
}

.descripton .h2,
.descripton h2 {
  font-size: 30px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: bold;
}

.descripton {
  padding: 50px !important;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.works {
  overflow: hidden;
  width: 100vw;
  height: auto;
}

html {
  overflow: hidden;
  overflow-y: scroll !important;
  width: 100vw;
  background-color: black;
}

section {
  overflow: hidden;
  width: 100vw;
}

#canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /*pointer-events: none;*/
  height: 750px;
}

.youtube-img {
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  cursor: pointer;
  margin-right: 10px;
}
